<template>
  <div class="bg-img-fix" :style="{ 'background-image': `url(${img})` }" style="position: relative;">
    <container class="container text-white overlay-black-dark" padding="80px 12px">
      <div class="flex-column-sm align-items-start-sm align-items-center"
        style="display: flex; justify-content: space-between">
        <div style="margin-right: 20px">
          <h2 class="text-white" style="font-weight: bold">我们的征途是星辰大海</h2>
        </div>
        <a-button style="font-weight: bold" type="primary" size="large">加入我们</a-button>
      </div>
    </container>
  </div>
</template>

<script>
export default {
  name: 'JoinUs',
  data() {
    return {
      img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/management-message/image2.jpg'
    }
  }
}
</script>

<style scoped></style>
